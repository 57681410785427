import request from "@/static/js/request";

import md5 from "js-md5";
import { getChannelInfo } from "@/plugin/webConvert";

/*登录*/
export function loginRegister(phone, password) {
  password = md5(password);
  const data = {
    phone: phone,
    psw: password,
    loginSource: "PC_WEB_LOGIN",
  };
  return request({
    url: "/login",
    method: "post",
    qsRequire: true,
    data: data,
  });
}
/*注册*/
export function register(data) {
  data = JSON.parse(JSON.stringify(data));
  //console.log("参数", data);
  return request({
    url: "/register/submit",
    method: "post",
    data: data,
    qsRequire: true,
  });
}

/*发送手机验证码-登录
 * type AUTH_LOGIN ,REGISTER,RESET_PASSWORD
 * */
export function sendPhoneCode(mobilePhone, userName, type) {
  let data = {
    mobilePhone,
    dialCode: "86",
    userName,
    type,
  };
  return request({
    url: "/sms/send",
    method: "post",
    qsRequire: true,
    data: data,
  });
}

// 手机验证码登录
export function bindWithPhone(params) {
  return request({
    url: "/wxmp/bindWithPhone",
    method: "get",
    qsRequire: true,
    params,
  });
}

/*发送邮箱验证码
 * type AUTH_LOGIN ,REGISTER,RESET_PASSWORD,CHANGE_EMAIL
 * */
export function sendEmailCode(email, userName, type) {
  let data = {
    email,
    userName,
    type,
  };
  //console.log("参数", data);
  return request({
    url: "/email/send",
    method: "post",
    qsRequire: true,
    data: data,
  });
}
/*验证验证码
 * type AUTH_LOGIN ,REGISTER,RESET_PASSWORD,CHANGE_EMAIL
 * */
export function verifyCode(phoneOrEmail, code, type) {
  let data = {
    phoneOrEmail,
    code,
    type,
  };
  return request({
    url: "/verifyCode",
    method: "post",
    qsRequire: true,
    data: data,
  });
}

/*验证码登录*/
export function loginByPhoneCode(phone, code, password) {
  const {from,data:{registerSource}} = getChannelInfo();
  const data = {
    phoneOrEmail: phone,
    code: code,
    psw: password,
    loginSource: "PC_WEB_LOGIN",
    registerSource,
  };
  return request({
    url: "/login/code",
    method: "post",
    qsRequire: true,
    data: data,
  });
}

/*重置密码*/
export function resetPsw(phoneOrEmail, psw, code) {
  let data = {
    phoneOrEmail,
    psw,
    code,
  };
  //console.log("参数", data);
  return request({
    url: "/resetPsw",
    method: "post",
    qsRequire: true,
    data: data,
  });
}

/** 扫码登录-生成二维码 */
export function qrcodeUrl(data) {
  return request({
    url: "/wxmp/qrcodeUrl",
    params: data,
    qsRequire: true,
    method: "get",
    ignoreLoading:true,
  });
}
export function loginOrBind(params) {
  return request({
    url: "wxmp/loginOrBind",
    method: "get",
    qsRequire: true,
    params: params,
  });
}
export function checkUsername(params) {
  return request({
    url: "/register/checkUsername",
    method: "post",
    qsRequire: true,
    data: params,
  });
}

// 获取appId
export function getappId() {
  return request({
    url: "wxmp/codeAuth/params",
    method: "get",
  });
}

// 通过code获取用户信息
export function getWxUser(params) {
  return request({
    url: "/wxmp/codeLogin",
    method: "get",
    qsRequire: true,
    params,
  });
}

// 查询第三方账号关联
export function userAuths(params) {
  return request({
    url: "/userAuths",
    method: "get",
    qsRequire: true,
    params,
  });
}

// 绑定微信号
export function bindWxmp(params) {
  return request({
    url: "/wxmp/bind",
    method: "get",
    qsRequire: true,
    params,
  });
}

// 解绑
export function dismissAccount(params) {
  return request({
    url: "/dismissAccount",
    method: "post",
    qsRequire: true,
    params,
  });
}

// 通过链接跳转
export function toSpaceApi(params) {
  return request({
    url: "/login/eToken",
    method: "post",
    qsRequire: true,
    params,
  });
}

// 招募体验官
export function activities(params) {
  return request({
    url: "/activities/submit",
    method: "post",
    data: params,
  });
}

// 登出
export function logout() {
  return request({
    url: "/logout",
    method: "post",
  });
}
