import { querySearchMainSpace } from "@/api/space/index.js";
import store from "@/store/index.js";

export const appClickHandle = (app) => {
  // 当前应用未发布，弹商务咨询
  if (!app.path) {
    store.commit("setContactusDialog", true);
    return;
  }

  // const tempPage = window.open("", "_blank"); //窗口被拦截后 tempPage 为 null
  if (app.path.indexOf("http") !== -1) {
    // tempPage.location = ; // "https://work.weixin.qq.com/kfid/kfc2075c0e45d360cdc";
    window.open(app.path, "_blank");
    return;
  }
  let userInfo = localStorage.getItem("editor_userInfo");
  let token = null;
  if (userInfo) {
    token = JSON.parse(userInfo)?.value.token;
  }
  querySearchMainSpace().then((res) => {
    let space = res.data;
    if (space) {
      let path = app.path ?? space.tenantDomain;
      if (app.path && app.path.indexOf("http") == -1) {
        path = `path=${encodeURIComponent(path)}&token=${token}`;
        let winPath = `${space.tenantDomain}?${path}`;

        // if (tempPage) {
        //   // tempPage.location = winPath;

        // } else {
        //   window.open(winPath, "_blank");
        // }
        window.open(winPath, "_blank");
        // window.location.reload();
        return;
      }
    }
  });
};
