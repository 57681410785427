export const BrowserSize = () => {
  var docEl = document.documentElement,
    clientWidth,
    clientHeight,
    clientWidth = docEl.clientWidth;
  clientHeight = docEl.clientHeight;
  if (!clientWidth) return;
  if (!docEl.addEventListener) return;

  if (clientWidth > 1920) {
    clientWidth = 1920;
  } else if (clientWidth < 1400) {
    clientWidth = 1440;
  }
  if (clientWidth > clientHeight) {
    docEl.style.fontSize = clientWidth / 10 + "px";
  } else {
    docEl.style.fontSize = clientWidth / 10 + "px";
  }
};
