export default {
  loading: false, // loading状态
  userInfo: {},
  registrationWay: "REGISTER", // 注册方式
  loginRegistDialogVisible: false,
  searchStr: "",
  sourceType: "CS,AI_MANUAL", // 查询当前作品类型
  knowlegeParams: [],
  dateFilterList: [],
  scrollArrowShow: false,
  refreshKnowledglistKey: "",
  getPageUrl: "", //当前要跳转的路径
  isNowWin: 0, //是否在当前窗口执行 (1导航栏产品页面跳转逻辑0,默认跳转当前项目中路由 -1 默认不跳转,停留当前页面)
  // 隐藏导航
  // 作品过滤
  spaceIndex: -1,
  classIndex: -1,
  visibleIndex: -1,
  orderIndex: -1,
  filterParams: {
    search: "",
    spaceId: "",
    classificationId: "",
    visibleType: null,
    orderType: 2,
  },
  filterIndex: -1,
  // 顶部未支付订单
  topOrderInfo: null,
  appList: [], // 试用应用列表
  contactusDialog: false, // 显示联系我们弹窗
  isMobile: false, // 是否是移动端
  isMobileMenu: false, // 移动端菜单
};
