import { getOrderList } from "@/api/pay";
import { queryMineBaseUserInfo } from "@/api/mine";
import { setUserInfo } from "@/static/js/userInfo";
import { getAppList } from "@/api/createProject/index";
// 触发修改loading
export const changeLoading = ({ commit }, payload) => {
  commit("changeLoading", payload);
};

export const changeUserInfo = ({ commit }, payload) => {
  commit("changeUserInfo", payload);
};

// 获取最新订单信息
export const GetNewOrder = ({ commit }) => {
  const params = {
    orderStatus: 0,
    pageNum: 1,
    pageSize: 1,
  };
  getOrderList(params).then((res) => {
    const order = res.data[0];
    commit("changeTopOrderInfo", order ? order : null);
  });
};

// 获取最新用户信息
export const UpdateUserInfo = ({ commit }) => {
  queryMineBaseUserInfo().then((res) => {
    setUserInfo(res.data);
  });
};

// 获取试用app列表
export const getuseAppList = ({ commit }) => {
  getAppList().then((res) => {
    let data = res.data;
    commit("changeAppList", data);
  });
};
