import router, { getFirstPageLoading, hookMobileVisit } from "@/router";
import { getUserInfo } from "@/static/js/userInfo";
import store from "@/store";
import showMessage from "../../static/js/message";

router.beforeEach((to, from, next) => {
  document.title = to.query.name ?? to.meta.title;
  if(hookMobileVisit(to)){
    return next(false);
  }
  // 判断是否需要登录
  // 从中获取用户信息，判断是否已登录
  if (!to.meta.requiresAuth || getUserInfo()) {
    next();
  } else {
    showMessage("w", "重新登录");
    /*去登录页面*/
    let width = document.documentElement.clientWidth;
    if (width > 750) {
      store.commit("changeLoginDialogVisible", 7);
      next(false);
    } else {
      next('/login')
    }
  }

});


router.afterEach((to) => { });

