<template>
  <div class="footer-root">
    <div class="footer">
      <div class="new-footer">
        <div class="left-logo">
          <img class="new-logo" :src="appFooterLogo" alt="" />
          <div>
            <div>浙江省杭州市上城区四季青街道</div>
            <div>顺福商务中心一幢 1703</div>
            <div>官方热线: 400-618-0681</div>
            <div>商务合作: bd@czy3d.com</div>
            <div>媒体合作: pr@czy3d.com</div>
          </div>
          <div class="mobile-app">
            <div class="app">
              <img src="./wxgzh.jpg" alt="" />
              <div class="name">超真云<br />公众号</div>
            </div>
            <div class="app">
              <img src="./wxapp.jpg" alt="" />
              <div class="name">超真云手册<br />小程序</div>
            </div>
            <div class="app">
              <img src="./wxAiApp.jpg" alt="" />
              <div class="name">超真云AI<br />小程序</div>
            </div>
          </div>
          <div class="left-logo-footer">
            <div class="left-logo-footer-left">
              <div class="wx-img">
                <el-popover popper-class="footer-popover" placement="top" width="150" trigger="hover">
                  <img style="width: 126px; height: 126px" src="./wxgzh.jpg" alt="" />
                  <img style="padding-top: 8px" slot="reference" src="./wxLogo.svg" alt="" />
                </el-popover>
              </div>

              <!-- <el-popover placement="top" width="150" popper-class="popper-class" trigger="hover">
                <img style="width: 126px; height: 126px" src="./dyLogo.svg" alt=""> -->
              <img src="./dyLogo.svg" alt="" @click="toNewTab('dy')" />
              <!-- </el-popover> -->
              <!-- <el-popover placement="top" width="150" popper-class="popper-class" trigger="hover">
                <img style="width: 126px; height: 126px" src="./xhsLogo.svg" alt=""> -->
              <img src="./xhsLogo.svg" alt="" @click="toNewTab('xhs')" />
              <!-- </el-popover> -->
            </div>
            <div class="left-logo-footer-right">
              <el-popover popper-class="footer-popover" placement="top" width="150" trigger="hover">
                <img style="width: 126px; height: 126px" src="./wxapp.jpg" alt="" />
                <div slot="reference" class="left-logo-footer-right-manual">
                  <img src="./wxLogo.svg" alt="" />
                  <div>超真云手册小程序</div>
                </div>
              </el-popover>
              <el-popover popper-class="footer-popover" placement="top" width="150" trigger="hover">
                <img style="width: 126px; height: 126px" src="./wxAiApp.jpg" alt="" />
                <div slot="reference" class="left-logo-footer-right-manual">
                  <img src="./wxLogo.svg" alt="" />
                  <div>
                    超真云AI小程序
                  </div>
                </div>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="center-info">
          <div class="center-info-item" v-for="(i, ind) in footerRouteList" :key="ind">
            <div class="center-info-item-title">{{ i.name ? i.name : `&nbsp;` }}</div>
            <div class="can-trigger" v-for="(j, index) in i.children" @click="toGo(j)" :key="index">
              {{ j.name }}
            </div>
          </div>
          <div class="center-info-btn">
            <div class="center-info-btn-item" :class="{ 'center-info-btn-item-active': !isEnglish }"
              @click="changLanguage('zh-CN')">中文</div>
            <div class="center-info-btn-item" :class="{ 'center-info-btn-item-active': isEnglish }"
              @click="changLanguage('en-US')">EN</div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <div @click="goBeiAn" class="pointer">Copyright © 2024 炽橙科技版权所有 / 浙 ICP 备19022962号-3</div>
        <div class="rigth">
          <div class="can-trigger" @click="$router.push('/SerAgreement')">服务条款</div>
          <div class="can-trigger" @click="$router.push('/Complaints')">隐私政策</div>
          <div class="can-trigger" @click="$router.push('/cookie')">Cookie政策</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { appClickHandle } from "@/static/js/goPage.js";
import { requireAuthBefore } from "@/static/js/authDecorators";
import { checkIsMobile, isAppleDevice } from "@/static/utils/utils";
export default {
  name: "index",
  data() {
    return {
      myNone: "display: none",
      list: [],
      links: [
        {
          name: "商务电话",
          content: "400 618 0681",
        },
        {
          name: "商务邮箱",
          content: "bddongiian@ccengine.com",
        },
        {
          name: "媒体联系",
          content: "炽橙科技公众号",
        },
      ],
      //尝试苹果系统下使用2倍图以防止模糊问题
      appFooterLogo: isAppleDevice() ? require("@/assets/newImg/res-czy3d/logo@2x.png") : require("@/assets/newImg/res-czy3d/logo.svg"),
      footerRouteList: [
        {
          name: "产品与解决方案",
          path: "/",
          children: [],
        },
        {
          name: "",
          path: "",
          children: [
            {
              name: "工业产品数据管理解决方案",
              path: "/schema?id=1",
            },
            {
              name: "新能源数字化交付解决方案",
              path: "/schema?id=2",
            },
            {
              name: "先进制造数字孪生体解决方案",
              path: "/schema?id=3",
            },
            {
              name: "工业垂直模型与智能应用解决方案",
              path: "/schema?id=4",
            },
            {
              name: "装备技术保障大模型一体化解决方案",
              path: "/schema?id=5",
            },
            {
              name: " 城市数字孪生解决方案",
              path: "/schema?id=6",
            },
          ],
        },
        {
          name: "资源",
          path: "/",
          children: [
            {
              name: "模型平台",
              path: "/modelPlat",
            },
            // {
            //   name: "帮助文档",
            //   path: "",
            // },
            {
              name: "服务支持",
              path: "/czy-serve",
            },
            {
              name: "炽橙杯 3D 创智大赛",
              path: "/contest/hgd",
            },
          ],
        },
        {
          name: "公司",
          path: "/",
          children: [
            {
              name: "炽橙科技",
              path: "https://www.ccengine.com/",
            },
            {
              name: "选择超真云",
              path: "/chooseCZY",
            },
            {
              name: "精彩超真云",
              path: "/suchPerson",
            },
            {
              name: "加入我们",
              path: "https://www.zhipin.com/gongsi/d6bf748c4814401b0HZ72N60GQ~~.html?ka=search_rcmd_company_name_d6bf748c4814401b0HZ72N60GQ~~_custompage",
            },
          ],
        },
      ],
      isEnglish: false,
    };
  },
  computed: {
    ...mapGetters(["appList"]),
  },
  watch: {
    // 监听当前路由
    appList(n) {
      if (n) {
        this.footerRouteList[0].children = [];
        n.forEach((item) => {
          this.footerRouteList[0].children.push({
            name: item.name.replaceAll("|", ""),
            path: item.czyRouter,
            type: 0,
          });
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch("getuseAppList");
    this.$store.dispatch("getuseAppList");
    let btn = document.getElementById("btn-gm");
    let that = this;
    if (!btn) return;
    if (!btn) return;
    btn.onmouseover = function () {
      that.myNone = "display: inline-block";
    };
    btn.onmouseout = function () {
      that.myNone = "display: none";
    };
  },
  methods: {
    goBeiAn() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_top");
    },
    // 切换语言
    changLanguage(type) {
      console.log(type);
      if (type == "en-US") {
        this.isEnglish = true;
      } else {
        this.isEnglish = false;
      }
    },
    toGo(data) {

      try {
        TDAPP.onEvent('页脚' + data.name.replaceAll(" ", ""), '', '');
      } catch (error) {

      }
      if (data.type == 0) {
        this.toApp(data)
        return;
      } else if (data.path.includes("http")) {
        window.open(data.path, "_blank");
        return;
      }
      this.$router.push({ path: data.path });
    },
    @requireAuthBefore()
toApp(data) {
  data.goPageStatus = 1;
  this.$store.commit("chanPageUrl", data);
  appClickHandle(data);
},
toNewTab(type) {
  if (type == "dy") {
    window.open("https://www.douyin.com/user/MS4wLjABAAAAMMIFg3yyul8Zsqt6Tv8fXsm4w0BKF3HzGwalcOU_m_c", "_blank");
  } else {
    window.open("https://www.xiaohongshu.com/user/profile/65d5b447000000000503060f", "_blank");
  }
},
  },
};
</script>

<style lang="scss">
.footer-popover {
  border-radius: 15px !important;
}
</style>

<style lang="scss" scoped>
.mobile-app {
  display: none;
  margin: 20px 0;

  .app {
    margin-right: 8px;

    img {
      width: 80px;
      height: 80px;
      border-radius: 8px;
    }

    .name {
      font-family: PingFang SC;
      font-size: 11px;
      font-weight: 400;
      line-height: 15px;
      text-align: center;
      color: #555555;
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.footer-root:first-child {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.can-trigger {
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: #ed481d;
  }
}

.copyright {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  margin: auto;

  .rigth {
    display: flex;
    width: 240px;
    justify-content: space-between;
  }
}

.footer {
  background-color: rgba(247, 248, 250, 1);
  padding-top: 30px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;

  // &::before{
  //   content: "";
  //   position: absolute;
  //   top: -30px;
  //   left: 0;
  //   width: 100%;
  //   height: 60px;
  //   color:#ed481d;
  //   border-radius: 30px 30px 0 0;
  //   background-color: #f7f8fa;
  // }
}

.new-footer {
  display: flex;
  align-items: flex-start;
  width: $mainCenter;
  margin: 0 auto;
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  color: #000000;
  padding-bottom: 54px;
  border-bottom: 1px solid #000000;

  .left-logo {
    width: 368px;
    margin-right: 50px;

    .new-logo {
      width: 198px;

      &+.new-logo {
        margin-left: 52px;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      margin-top: 20px;

      &-left {
        width: 200px;
        display: flex;
        justify-content: space-between;
        padding-right: 15px;
        border-right: 1px solid #c2c2c2;

        @media screen and (max-width: 768px) {
          width: auto;
          border-right: none;
        }

        .wx-img {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        img {
          cursor: pointer;

          @media screen and (max-width: 768px) {
            margin-right: 20px;
          }
        }
      }

      &-right {
        flex: 1;
        padding-left: 15px;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
          display: none;
        }

        &-manual {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          margin-right: 10px;

          img {
            width: 16px;
            height: 16px;
          }

          div {
            text-align: center;
            line-height: 22px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .center-info {
    flex: 1;
    display: flex;
    position: relative;

    @media screen and (max-width: 768px) {
      display: none;
    }

    // > div + div {
    //   margin-left: 42px;
    // }
    // > div {
    //   display: flex;
    //   flex-direction: column;
    // }
    &-item {
      margin-right: 50px;

      &-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        color: #000000;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100px;
      height: 36px;
      background: #d8d8d8;
      border-radius: 18px;
      color: #000;
      text-align: center;
      display: flex;
      justify-content: space-between;
      padding: 3px;

      &-item {
        width: 50px;
        text-align: center;
        line-height: 30px;
        border-radius: 15px;
        cursor: pointer;
        transition: all 0.3s linear;

        &:hover {
          background: #fff;
        }

        &-active {
          background-color: #fff;
        }
      }
    }
  }

  .right-links {
    display: flex;

    .links-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &+.links-img {
        margin-left: 52px;
      }

      img {
        width: 120px;
        height: 120px;
        margin-bottom: 10px;
        box-shadow: 0px 5px 20px 0px #0000000d;
      }
    }
  }

  .popper-class {
    // img{
    width: 150px;
    height: 150px;
    // }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 20px 2.5vw !important;
  }

  .copyright {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    >div:first-child {
      width: 100%;
      font-size: 12px;
    }

    .rigth {
      display: flex;
      justify-content: center;
      width: 100%;

      .can-trigger {
        font-size: 11px;
        color: #555555;
        width: auto;
        padding: 0 10px;
      }
    }
  }

  .new-footer {
    width: 100%;
    position: relative;
    font-size: 12px;
    line-height: 25px;
    flex-direction: column;
    padding-bottom: 20px;

    .left-logo {
      flex-direction: column;
      margin-bottom: 20px;

      .new-logo {
        width: 165px;

        &+.new-logo {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }

    .center-info {
      flex-direction: column;

      >div:first-child {
        display: flex;
        flex-direction: row;
      }

      >div+div {
        margin-left: 0;
      }
    }

    .right-links {
      position: absolute;
      right: 0;
      bottom: 20px;

      display: flex;

      .links-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &+.links-img {
          margin-left: 20px;
        }

        img {
          width: 90px;
          height: 90px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
