import axios from "axios";
//import NProgress from "nprogress";
import "nprogress/nprogress.css";
import qs from "qs";
import md5 from "js-md5";
import { getUserInfo, removeUserInfo } from "./userInfo";
import showMessage from "../../static/js/message";
import store from "@/store/index";
import router from "@/router/index";

axios.defaults.withCredentials = false;

// 创建axios实例
const service = axios.create({
  baseURL: process.env["VUE_APP_BASE_API"], // api的base_url
  timeout: 600000, // 请求超时时间
});

//let loadingInstance;
// request拦截器
service.interceptors.request.use(
  (config) => {
    // NProgress.start();
    !config.ignoreLoading && store.commit("changeLoading", true);
    // loadingInstance = Loading.service(null);
    /*格式化参数*/
    if (config.qsRequire) {
      let data = config.data;
      if (data && data instanceof Object) {
        config.data = qs.stringify(config.data);
      }
    }
    const userInfo = getUserInfo();
    if (userInfo && userInfo.token) {
      config.headers["Authorization"] = getUserInfo().token; // 让请求携带自定义token
    } else {
      config.headers["Authorization"] = "";
    }
    // config.headers["ApiVersion"] = "v2";
    /*添加签名*/
    addSingParams(config);
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  (response) => {
    {
      // NProgress.done();
      // loadingInstance.close();
    }
    {
      /*针对不是自己公司的服务 如oss上传文件*/
      if (!response.config.url.startsWith(response.config.baseURL)) {
        return response;
      }
    }

    /**
     * code为非200是抛错 可结合自己业务进行修改
     */
    store.commit("changeLoading", false);
    const res = response.data;

    // 使用 responseType: "blob" 请求文件流时拦截报错信息
    if (response.config.responseType === "blob") {
      if (res.type === "application/json") {
        const reader = new FileReader();
        reader.readAsText(res, "utf-8");
        reader.onload = function () {
          const _res = JSON.parse(reader.result);
          if (_res.code === 401) {
            showLoginDialog();
          }
        };
        return Promise.reject("error");
      } else {
        return response.data;
      }
    }

    if (res.code == 200 || res.code == 201 || res.code == 30310) {
      return res;
    } else if (res.code === 401) {
      showLoginDialog();
      return Promise.reject("error");
    } else if (res.code === 500) {
      showMessage("e", res.message);
      return Promise.reject("error");
    } else {
      //console.log("获取信息失败", res);
      return res;
    }

    function showLoginDialog() {
      // 401:未登录;
      removeUserInfo();
      var screenWidth = document.documentElement.clientWidth;

      // ps：立即使用和订阅页面，不需要显示登录弹窗
      const url = window.location.href.split("/");
      const routerName = url[url.length - 1].split("?")[0];
      const list = ["members", "subscribe"];
      if (res.code === 401 && list.includes(routerName)) {
        return Promise.reject("error");
      }

      // 默认登陆弹出
      if (screenWidth > 750) {
        /*未登录弹出登录框*/
        store.commit("changeLoginDialogVisible", 7);
      } else {
        /*手机端-未登录弹出登录框*/
        router.push("/login");
      }
    }
  },
  (error) => {
    // NProgress.done();
    // loadingInstance.close();
    // 默认登陆弹出
    if (screenWidth > 750) {
      /*未登录弹出登录框*/
      store.commit("changeLoginDialogVisible", 7);
    }
    store.commit("changeLoading", false);
    showMessage("e", "响应超时，请重新登录");
    return Promise.reject(error);
  }
);

export function addSingParams(config) {
  let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  let maxPos = chars.length;
  let nonce = "";
  for (let i = 0; i < 6; i++) {
    nonce += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  let timestamp = new Date().getTime();

  let query = {
    // signKey: encodeURIComponent("puvfMUTavMEhP8P7XOtV"),
    appKey: "czyTg0MzIzMjIwNjg0OA",
    appSecret: "BF10D59D8B144DA1CC5ACBA8448AFAF7",
    nonce: nonce,
    timestamp: timestamp,
  };
  let queryArr = [];

  Object.keys(query)
    .sort()
    .forEach(function (key) {
      queryArr.push(key.concat("=", query[key]));
    });

  let sign = md5(queryArr.join("&"));
  config.headers.appKey = "czyTg0MzIzMjIwNjg0OA";
  config.headers.nonce = nonce;
  config.headers.timestamp = timestamp;
  config.headers.signature = sign;
}

export default service;
