import { upload360WebConvert, uploadBaiDuWebConvert } from "@/api/tracking";

const storageChannelKey = "PromotionChannels";
const storageChannelExtraDataKey = "PromotionChannelsExtraData";

const defaultData = {};

// export const
let channelFrom = sessionStorage.getItem(storageChannelKey) || ""; // 360 bing baidu
let channelData = (() => {
  const data = sessionStorage.getItem(storageChannelExtraDataKey);
  try {
    return data ? JSON.parse(data) : defaultData;
  } catch (e) {
    return defaultData;
  }
})();

export function setChannelData(data) {
  channelData = data;
  sessionStorage.setItem(storageChannelExtraDataKey, JSON.stringify(data));
}

export function setChannelFrom(from, data) {
  channelFrom = from;
  sessionStorage.setItem(storageChannelKey, from);
  setChannelData(data);
}

export function resetChannel() {
  channelFrom = "";
  channelData = defaultData;
  sessionStorage.removeItem(storageChannelKey);
  sessionStorage.removeItem(storageChannelExtraDataKey);
}

export function getChannelInfo() {
  return {
    from: channelFrom,
    data: channelData || defaultData,
  };
}

//记录登录框产生的有效注册行为
export function pointRegister() {
  const { from, data } = getChannelInfo();
  from && console.log("上报页面注册", from, data?.qhclickid);
  switch (from) {
    case "bing":
      window.uetq?.push("event", "signup", {});
      break;
    case "360":
      if (data.qhclickid) {
        upload360WebConvert(data.qhclickid, "REGISTERED");
      }
      break;
    case "baidu":
      console.log("baidu register");
      console.log(data);
      if (data.bd_vid) {
        uploadBaiDuWebConvert(data.logidUrl);
      }
      break;
    default:
  }
}

//页面进入的埋点 相当于pv //目前只适用于活动页 /marketing/240506
export function pointPageLoad() {
  console.log("----pageLoad");
  const { from, data } = getChannelInfo();
  from && console.log("上报页面进入:", from, data);
  switch (from) {
    case "bing":
      window.uetq?.push("pageLoad");
      break;
    case "360":
      if (data.qhclickid) {
        upload360WebConvert(data.qhclickid, "COUSTOMIZE");
      }
      break;
    default:
  }
}
