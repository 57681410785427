// 空间管理模块
import request from "@/static/js/request";

const baseUrl = "/mine/space";
/*默认空间*/
export function querySearchMainSpace(params) {
  return request({
    url: baseUrl + "/mainSpace",
    method: "get",
    params: params
  });
}
/*** 设置默认空间 */
export function setMain(params) {
  return request({
    url: baseUrl + "/setMain/" + params,
    method: "post"
  });
}
/**创建空间 */
export function saveSpace(params) {
  return request({
    url: baseUrl + "/save",
    method: "post",
    data: params
  });
}
/**修改空间 */
export function updateSpace(params) {
  return request({
    url: baseUrl + "/update",
    method: "post",
    data: params
  });
}
/**空间详情 */
export function getSpaceInfo(params) {
  return request({
    url: baseUrl + "/info/" + params,
    method: "get"
  });
}
/**空间列表 */
export function getSpaceAll(params) {
  return request({
    url: baseUrl + "/all",
    method: "get",
    params: params
  });
}

export function editorSpaces(params) {
  return request({
    url: baseUrl + "/editorSpaces",
    method: "get",
    params: params
  });
}
/**退出空间 */
export function exitSpace(params) {
  return request({
    url: baseUrl + "/exit/" + params,
    method: "post"
  });
}
/**删除空间 */
export function delSpace(params) {
  return request({
    url: baseUrl + "/del/" + params,
    method: "post"
  });
}

/**其他空间列表 */
export function getSpaceList(params) {
  return request({
    url: baseUrl + "/otherList",
    method: "get",
    params: params
  });
}

/** 成员列表 */
export function getSpaceUser(params) {
  return request({
    url: baseUrl + "/users",
    method: "get",
    params: params
  });
}

/**添加空间成员 */
export function addSpaceUser(params) {
  return request({
    url: baseUrl + "/addUser",
    method: "post",
    qsRequire: true,
    params: params
  });
}
/**修改空间成员权限 */
export function changeUserRole(params) {
  return request({
    url: baseUrl + "/changeUserRole",
    method: "post",
    data: params
  });
}
/**撤销空间成员权限 */
export function delUser(params) {
  return request({
    url: baseUrl + "/delUser",
    method: "post",
    params: params
  });
}

/** 空间下的知识点(作品) */
export function getKnowledgeList(params) {
  return request({
    url: baseUrl + "/knowledgeList",
    method: "get",
    params: params
  });
}
// 所有空间列表
export function getManagerSpaces() {
  return request({
    url: baseUrl + "/managerSpaces",
    method: "get"
  });
}

// 转移知识点
export function transferKnowledge(data) {
  return request({
    url: baseUrl + "/transferKnowledge",
    method: "post",
    qsRequire: true,
    data
  });
}
// 接受知识点
export function receiveKnowledge(data) {
  return request({
    url: baseUrl + "/receiveKnowledge",
    method: "post",
    qsRequire: true,
    data
  });
}
/***空间下的作品集 */
export function getPortfolioList(params) {
  return request({
    url: "/user/space/portfolioList",
    method: "get",
    params: params
  });
}

// 升级主站
export function upgradeNamespace(id, data) {
  return request({
    url: `/mine/space/upgradeNamespace/${id}`,
    method: "post",
    qsRequire: true,
    data
  });
}
export function unusedNamespace() {
  return request({
    url: `/mine/space/unusedNamespace`,
    method: "get"
  });
}
