// 修改loading显示
export const changeLoading = (state, loading) => {
  state.loading = loading;
};

// 修改loading显示
export const changeUserInfo = (state, userInfo) => {
  state.userInfo = userInfo;
};

// 修改loading显示
export const changeLoginDialogVisible = (state, loginRegistDialogVisible) => {
  state.loginRegistDialogVisible = loginRegistDialogVisible;
};
// 修改登录方式

export const changeRegistrationWay = (state, registrationWay) => {
  state.registrationWay = registrationWay;
};
export const changeSearchStr = (state, searchStr) => {
  state.searchStr = searchStr;
};

export const setKnowlegeParams = (state, param) => {
  state.knowlegeParams = param;
};

export const changeDateFilterParams = (state, dateFilterList) => {
  state.dateFilterList = dateFilterList;
};

export const scroolArrowShow = (state, flag) => {
  state.scrollArrowShow = flag;
};

//刷新对应的知识点列表
export const freshKnowlist = (state) => {
  state.refreshKnowledglistKey = new Date().getTime();
};
export const upFilterSatus = (state, param) => {
  state[param.type] = param.value;
};
export const upfilterParams = (state, param) => {
  state.filterParams[param.k] = param.v;
};

export const changeTopOrderInfo = (state, data) => {
  state.topOrderInfo = data;
};

export const changeAppList = (state, data) => {
  state.appList = data;
};

export const chanPageUrl = (state, data, status) => {
  state.getPageUrl = data;
  state.isNowWin = data.goPageStatus;
};
export const chanIsNowWin = (state, data) => {
  state.isNowWin = data;
};
export const setContactusDialog = (state, data) => {
  state.contactusDialog = data;
};

export const setIsMobile = (state, data) => {
  state.isMobile = data;
};

export const setIsMobileMenu = (state, data) => {
  state.isMobileMenu = data;
};
