//尽可能保持router尽快加载，影响 pc 端和 移动端互跳
import router from "./router";
import Vue from "vue";
import "@/router/hooks/permission";
import App from "./App.vue";
import store from "./store";
import ElementUI from "element-ui";
import Vuex from "vuex";
import showMessage from "@/static/js/message";
Vue.prototype.$showMessage = showMessage;
import VueTouch from "vue-touch";
import "@/icons/index"; // icon
import "@/static/css/main.scss";
import "@/static/css/transition.scss";
/*fa-icon*/
// import "font-awesome/css/font-awesome.min.css";
import { initVue } from "@/init/initVue";

// 引入动画样式
import "animate.css";
import VueLazyload from "vue-lazyload";
Vue.use(ElementUI);
Vue.use(Vuex);
import "@/static/js/fexilb.js";

Vue.use(VueTouch, {
  name: "v-touch",
});
initVue();

Vue.use(VueLazyload, {
  // 设置懒加载时的占位图
  loading: "path/to/loading-image.gif",
  // 设置图片加载失败时的占位图
  error: "path/to/error-image.png",
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
